import React from 'react';
import _ from 'lodash';
import { useRouter } from 'next/router';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import getConfig from 'next/config';

import useResponsive from '../helpers/responsive';
import { cmsService, geolocationService } from '../services/homii-services';
import CityCarousel from '../components/city-carousel/city-carousel.container';
import SearchBar from '../components/search-bar/search-bar.container';
import {
    getAllCities,
    setSelectedCityByName,
    setSelectedCityByClosestCoordinates,
} from '../common-reducers/city-reducer/city.actions';
import LazyFeaturedSpaces from '../components/featured-spaces/lazy-featured-spaces.container';
import { getAllBuildings } from '../common-reducers/buildings-reducer/building.actions';
import { parseTitle } from '../helpers/title-parser';
import StayOrStyleSection from '../components/find-a-space-components/stay-or-style-section.component';
import SpaceSection from '../components/find-a-space-components/space-section.component';
import ReviewSection from '../components/find-a-space-components/review-section.component';
import MobileAppSection from '../components/home-page-components/mobile-app-section.component';
import { setGeoErrorAction } from '../common-reducers/geolocation-reducer/geolocation.reducer';
import CarouselContainer from '../components/common-components/carousel-container.component';
import { Layout, Footer, MetaData, FadeInAnimation } from '../components/common-components/index';

const useStyles = makeStyles((theme) => ({
    headerContainer: {
        marginTop: 120,
        [theme.breakpoints.down('sm')]: {
            marginTop: 70,
        },
    },
    headerParagraph: {
        marginTop: 30,
        marginBottom: 100,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 50,
        },
    },
}));

const FindaSpace = (props) => {
    const classes = useStyles();
    const { isMobile, isTablet } = useResponsive();
    const router = useRouter();
    const { data, baseUrlApp } = props;
    const seoTitle = _.get(data, 'acf.seo_title');
    const seoMetaDesc = _.get(data, 'acf.seo_meta_description');
    const heroTitle = _.get(data, 'acf.hero_title');
    const heroSubHeading = _.get(data, 'acf.hero_sub_heading');
    const citiesTitle = _.get(data, 'acf.city_title');
    const citiesParagraph = _.get(data, 'acf.city_paragraph');
    const reviews = _.get(data, 'acf.review');
    const stayOrStyleTitle = _.get(data, 'acf.style_section_title');
    const stayOrStyleParagraph = _.get(data, 'acf.style_section_paragraph');
    const spaceTitle = _.get(data, 'acf.space_section_title');
    const spaceParagraph = _.get(data, 'acf.space_section_paragraph');
    const mobileAppTitle = _.get(data, 'acf.mobile_title');
    const mobileAppParagraph = _.get(data, 'acf.mobile_paragraph');
    const mobileAppIcon = _.get(data, 'acf.mobile_icon.url');
    const mobileAppIconText = _.get(data, 'acf.mobile_icon_text');
    const mobilePrimaryImgSrc = _.get(data, 'acf.mobile_primary_image.url');
    const mobilePrimaryImgAlt = _.get(data, 'acf.mobile_primary_image_alt');
    const mobileAppSecondaryImgSrc = _.get(data, 'acf.mobile_secondary_image.url');
    const mobileAppSecondaryImgAlt = _.get(data, 'acf.mobile_secondary_image_alt');
    const isMainHeaderOne = true;

    return (
        <Layout>
            <MetaData title={seoTitle} desc={seoMetaDesc} canonical={`${baseUrlApp}/home`} />
            <Container maxWidth="lg" className={classes.headerContainer}>
                {parseTitle(heroTitle, 'find_a_space_header', isMainHeaderOne)}
                <FadeInAnimation>
                    <div>
                        <p className={classes.headerParagraph}>{heroSubHeading}</p>
                    </div>
                </FadeInAnimation>
                {!isMobile && (
                    <SearchBar
                        type="Bookings"
                        query={router.query}
                        isMobile={isMobile}
                        isTablet={isTablet}
                    />
                )}
                {isMobile && <SearchBar isMobile={isMobile} isTablet={isTablet} type="Mobile" />}
            </Container>

            <CarouselContainer>
                <CityCarousel
                    isMobile={isMobile}
                    isTablet={isTablet}
                    title={citiesTitle}
                    paragraph={citiesParagraph}
                    type="FindaSpace"
                />
            </CarouselContainer>

            <FeaturedSpacesContainer>
                <LazyFeaturedSpaces
                    title="Featured Spaces"
                    isMobile={isMobile}
                    isTablet={isTablet}
                    isGrid
                />
            </FeaturedSpacesContainer>

            <Container maxWidth="lg">
                <StayOrStyleSection title={stayOrStyleTitle} paragraph={stayOrStyleParagraph} />

                <SpaceSection title={spaceTitle} paragraph={spaceParagraph} />
            </Container>

            <ReviewSection reviews={reviews} />

            <MobileAppSectionContainer>
                <MobileAppSection
                    isMobile={isMobile}
                    title={mobileAppTitle}
                    paragraph={mobileAppParagraph}
                    icon={mobileAppIcon}
                    iconText={mobileAppIconText}
                    primaryImgSrc={mobilePrimaryImgSrc}
                    primaryImgAlt={mobilePrimaryImgAlt}
                    secondaryImgSrc={mobileAppSecondaryImgSrc}
                    secondaryImgAlt={mobileAppSecondaryImgAlt}
                />
            </MobileAppSectionContainer>

            <Footer isMobile={isMobile} isTablet={isTablet} />
        </Layout>
    );
};

FindaSpace.getInitialProps = async (ctx, dispatch, getState) => {
    const resp = await cmsService.RetrieveWordPressPage('find-a-space');
    const { publicRuntimeConfig } = getConfig();
    const { selectedCity } = getState().cityReducer;
    let geolocatedData = null;
    await dispatch(getAllCities());
    await dispatch(getAllBuildings());

    try {
        const clientIp = _.get(ctx, 'req')
            ? ctx.req.headers['x-azure-clientip'] ||
              ctx.req.headers['x-forwarded-for'] ||
              ctx.req.connection.remoteAddress
            : null;

        if (clientIp && publicRuntimeConfig.HOMII_FEATURE_PAID_GEO_SERVICE) {
            geolocatedData = await geolocationService.getGeolocationDetails(clientIp);
        }

        if (!geolocatedData) {
            geolocatedData = await geolocationService.getGeolocationDetailsFallback();
        }
    } catch (error) {
        dispatch(setGeoErrorAction(error));
    }

    if (!selectedCity) {
        if (_.get(geolocatedData, 'latitude') && _.get(geolocatedData, 'longitude')) {
            await dispatch(
                setSelectedCityByClosestCoordinates(
                    geolocatedData.latitude,
                    geolocatedData.longitude,
                ),
            );
        } else {
            await dispatch(setSelectedCityByName('Durban'));
        }
    }
    return { data: resp };
};

export default FindaSpace;

FindaSpace.propTypes = {
    data: PropTypes.object,
    baseUrlApp: PropTypes.string,
};

const FeaturedSpacesContainer = styled.div`
    background-color: ${(props) => props.theme.featuredSpacesBackground};
`;

const MobileAppSectionContainer = styled.div`
    background-color: transparent;
`;
