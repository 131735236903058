import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Router from 'next/router';

import { FadeInAnimation, LinkUnderlineAnimation } from '../common-components';
import { theme as themeColors } from '../../../utils/theme';
import FindASpaceCard from '../common-components/find-a-space-card.component';

const useStyles = makeStyles((theme) => ({
    sectionContainer: {
        marginBottom: 200,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 60,
        },
    },
    sectionDescription: {
        marginTop: 20,
        marginBottom: 30,
        color: themeColors.paragraphMedium,
        maxWidth: '50%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '90%',
        },
    },
}));

const SpaceSection = (props) => {
    const classes = useStyles();
    const { title, paragraph } = props;

    return (
        <div id="space_section" className={classes.sectionContainer}>
            <FadeInAnimation>
                <div>
                    <h4>{title}</h4>
                    <ParagraphContainer>
                        <Paragraph className={classes.sectionDescription}>{paragraph}</Paragraph>
                        <LinkContainer>
                            <LinkUnderlineAnimation
                                id="learn_more_space"
                                href="/live/live-your-best-life#choose_header"
                            >
                                Learn More
                            </LinkUnderlineAnimation>
                        </LinkContainer>
                    </ParagraphContainer>
                </div>
            </FadeInAnimation>
            {renderCards()}
        </div>
    );
};

function renderCards() {
    const onBachelorClick = () => {
        Router.push('/bookings?category=bachelor').then(() => window.scrollTo(0, 0));
    };

    const onOneBedClick = () => {
        Router.push('/bookings?category=one-bedroom').then(() => window.scrollTo(0, 0));
    };

    const onTwoBedClick = () => {
        Router.push('/bookings?category=two-bedroom').then(() => window.scrollTo(0, 0));
    };

    return (
        <>
            <FadeInAnimation>
                <StyledGrid container spacing={3}>
                    <Grid item md={6} sm={12} xs={12}>
                        <FindASpaceCard
                            imageSource="/assets/images/studio-apartment-icon.png"
                            imageAlt="studio"
                            title="Studio Apartment"
                            description="Live, work and play in one space"
                            onClick={onBachelorClick}
                        />
                    </Grid>

                    <Grid item md={6} sm={12} xs={12}>
                        <FindASpaceCard
                            imageSource="/assets/images/bachelor-apartment_icon.png"
                            imageAlt="bunk-bed"
                            title="Bachelor Apartment"
                            description="Perfect for the professional single"
                            onClick={onBachelorClick}
                        />
                    </Grid>
                </StyledGrid>

                <StyledGrid container spacing={3}>
                    <Grid item md={6} sm={12} xs={12}>
                        <FindASpaceCard
                            imageSource="/assets/images/one-bedroom-icon.png"
                            imageAlt="bed"
                            title="One Bedroom Apartment"
                            description="Keep sleep separate and silent"
                            onClick={onOneBedClick}
                        />
                    </Grid>

                    <Grid item md={6} sm={12} xs={12}>
                        <FindASpaceCard
                            imageSource="/assets/images/two-bedroom-icon.png"
                            imageAlt="people"
                            title="Two Bedroom Apartment"
                            description="Need to share with a friend?"
                            onClick={onTwoBedClick}
                        />
                    </Grid>
                </StyledGrid>
            </FadeInAnimation>
        </>
    );
}

export default SpaceSection;

SpaceSection.propTypes = {
    title: PropTypes.string,
    paragraph: PropTypes.string,
};

const ParagraphContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
`;

const Paragraph = styled.p`
    word-wrap: normal;
    width: 80%;
`;

const LinkContainer = styled.div`
    margin-bottom: 37px;
    margin-left: 10px;
`;

const StyledGrid = styled(Grid)`
    margin-bottom: 15px;
`;
