import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { FadeInAnimation } from '../common-components';
import { theme as themeColors } from '../../../utils/theme';

const useStyles = makeStyles((theme) => ({
    sectionContainer: {
        backgroundColor: themeColors.sectionBackgroundDark,
        paddingTop: 220,
        paddingBottom: 220,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 120,
            paddingBottom: 40,
        },
    },
    gridContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    containerSettings: {
        maxWidth: 1100,
    },
    individualGrid: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 80,
        },
    },
}));

const ReviewSection = (props) => {
    const classes = useStyles();
    const { reviews } = props;
    return (
        <div className={classes.sectionContainer}>
            <Container maxWidth="md" classes={{ maxWidthMd: classes.containerSettings }}>
                <Grid container spacing={3} className={classes.gridContainer}>
                    {renderReviews(reviews, classes)}
                </Grid>
            </Container>
        </div>
    );
};

const renderReviews = (reviews, classes) => {
    if (!reviews) {
        return null;
    }
    return _.map(reviews, (review, index) => {
        const title = _.get(review, 'title');
        const paragraph = _.get(review, 'paragraph');
        const person = _.get(review, 'person');
        const date = _.get(review, 'date_of_review');
        let formattedDate = moment() - moment(date);
        formattedDate = moment(formattedDate).format('M');
        const stars = _.get(review, 'star_count');
        return (
            <Grid item md={5} className={classes.individualGrid} key={index}>
                <FadeInAnimation>
                    <div>
                        {renderStars(stars)}
                        <Title>&apos;{title}&apos;</Title>
                        <Paragraph>{paragraph}</Paragraph>
                        <Person>
                            {person} - {formattedDate} months ago
                        </Person>
                    </div>
                </FadeInAnimation>
            </Grid>
        );
    });
};

const renderStars = (starCount) => {
    const maxStars = 5;
    const starDifference = maxStars - starCount;
    const stars = [];
    for (let i = 0; i < starCount; i += 1) {
        stars.push(<StyledImg key={i} src="/assets/images/full_star.png" alt="yellow-start" />);
    }
    if (starDifference > 0) {
        for (let i = 0; i < starDifference; i += 1) {
            stars.push(
                <StyledImg
                    key={maxStars - starDifference + i}
                    src="/assets/images/empty_star.png"
                    alt="grey-start"
                />,
            );
        }
    }

    return stars;
};

export default ReviewSection;

ReviewSection.propTypes = {
    reviews: PropTypes.array,
};

const Title = styled.h4`
    color: ${(props) => props.theme.paragraphLight};
    margin-top: 30px;
    margin-bottom: 20px;
    @media only screen and (max-width: 700px) {
        width: 70%;
    }
`;

const Paragraph = styled.p`
    color: ${(props) => props.theme.paragraphLight};
`;

const Person = styled.h6`
    color: ${(props) => props.theme.paragraphMedium};
    margin-top: 30px;
`;

const StyledImg = styled.img`
    margin-right: 4px;
`;
