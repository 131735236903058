import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Router from 'next/router';
import getConfig from 'next/config';

import { FadeInAnimation, LinkUnderlineAnimation } from '../common-components';
import { theme as themeColors } from '../../../utils/theme';
import FindASpaceCard from '../common-components/find-a-space-card.component';

const { publicRuntimeConfig } = getConfig();

const useStyles = makeStyles((theme) => ({
    sectionContainer: {
        marginTop: 130,
        marginBottom: 100,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 60,
        },
    },
    sectionDescription: {
        marginTop: 20,
        marginBottom: 30,
        color: themeColors.paragraphMedium,
        maxWidth: '50%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '90%',
        },
    },
}));

const StayOrStyleSection = (props) => {
    const classes = useStyles();

    return (
        <div id="stay_or_style_section" className={classes.sectionContainer}>
            <FadeInAnimation>
                <div>
                    <h4>{props.title}</h4>
                    <ParagraphContainer>
                        <Paragraph className={classes.sectionDescription}>
                            {props.paragraph}
                        </Paragraph>
                        <LinkContainer>
                            <LinkUnderlineAnimation
                                href="/live/live-your-best-life#options_header"
                                id="learn_more_space"
                            >
                                Learn More
                            </LinkUnderlineAnimation>
                        </LinkContainer>
                    </ParagraphContainer>
                </div>
            </FadeInAnimation>
            {renderCards()}
        </div>
    );
};

function renderCards() {
    const onDailyClick = () => {
        Router.push('/bookings?term=Daily').then(() => window.scrollTo(0, 0));
    };

    const onMonthlyClick = () => {
        Router.push('/bookings?term=Monthly').then(() => window.scrollTo(0, 0));
    };

    const onPrivateApartmentClick = () => {
        Router.push('/bookings?style=private_apartment').then(() => window.scrollTo(0, 0));
    };

    const onPrivateBedroomClick = () => {
        Router.push('/bookings?style=private_bedroom').then(() => window.scrollTo(0, 0));
    };

    const onSharedMaleClick = () => {
        Router.push('/bookings?style=shared_male').then(() => window.scrollTo(0, 0));
    };

    const onSharedFemaleClick = () => {
        Router.push('/bookings?style=shared_female').then(() => window.scrollTo(0, 0));
    };

    const renderTermCards = () => (
        <StyledGrid container spacing={3}>
            {publicRuntimeConfig.HOMII_FEATURE_DAILY_TERM_ENABLED && (
                <Grid item md={6} sm={12} xs={12}>
                    <FindASpaceCard
                        imageSource="/assets/images/daily-rentals-icon.png"
                        imageAlt="clock"
                        title="Daily Rentals"
                        description="Flexible bookings"
                        onClick={onDailyClick}
                    />
                </Grid>
            )}

            <Grid item md={6} sm={12} xs={12}>
                <FindASpaceCard
                    imageSource="/assets/images/monthly-rentals-icon.png"
                    imageAlt="calendar"
                    title="Monthly Rentals"
                    description="Something a bit more permanent"
                    onClick={onMonthlyClick}
                />
            </Grid>
        </StyledGrid>
    );

    return (
        <>
            <FadeInAnimation>
                {publicRuntimeConfig.HOMII_FEATURE_DAILY_TERM_ENABLED && renderTermCards()}
                <StyledGrid container spacing={3}>
                    <Grid item md={6} sm={12} xs={12}>
                        <FindASpaceCard
                            imageSource="/assets/images/private-space-icon.png"
                            imageAlt="house-with-person"
                            title="Private Apartment"
                            description="Have the apartment to yourself"
                            onClick={onPrivateApartmentClick}
                        />
                    </Grid>

                    <Grid item md={6} sm={12} xs={12}>
                        <FindASpaceCard
                            imageSource="/assets/images/shared-space-icon.png"
                            imageAlt="house-with-people"
                            title="Private Bedroom"
                            description="Your own bedroom in a shared living space"
                            onClick={onPrivateBedroomClick}
                        />
                    </Grid>
                </StyledGrid>

                <StyledGrid container spacing={3}>
                    <Grid item md={6} sm={12} xs={12}>
                        <FindASpaceCard
                            imageSource="/assets/images/shared-room.png"
                            imageAlt="shared-room-with-curtains"
                            title="Shared Male"
                            description="Share a room with another male HOMii"
                            onClick={onSharedMaleClick}
                        />
                    </Grid>

                    <Grid item md={6} sm={12} xs={12}>
                        <FindASpaceCard
                            imageSource="/assets/images/shared-room.png"
                            imageAlt="shared-room-with-curtains"
                            title="Shared Female"
                            description="Share a room with another female HOMii"
                            onClick={onSharedFemaleClick}
                        />
                    </Grid>
                </StyledGrid>
                {!publicRuntimeConfig.HOMII_FEATURE_DAILY_TERM_ENABLED && renderTermCards()}
            </FadeInAnimation>
        </>
    );
}

export default StayOrStyleSection;

StayOrStyleSection.propTypes = {
    title: PropTypes.string,
    paragraph: PropTypes.string,
};

const ParagraphContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
`;

const Paragraph = styled.p`
    word-wrap: normal;
    width: 80%;
`;

const LinkContainer = styled.div`
    margin-bottom: 37px;
    margin-left: 10px;
`;

const StyledGrid = styled(Grid)`
    margin-bottom: 15px;
`;
