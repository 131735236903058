import _ from 'lodash';
import { Promise } from 'bluebird';

import { buildingService, mediaService } from '../../services/homii-services';
import {
    setIsLoadingAction,
    setCurrentBuildingAction,
    setBuildingLocationsAction,
    setBuildingsAction,
    setSelectedBuildingAction,
} from './building.reducer';
import { setCitiesAction } from '../city-reducer/city.reducer';

export function getCurrentBuilding() {
    return (dispatch, getState) => {
        const { cities } = getState().cityReducer;
        dispatch(setIsLoadingAction(true));
        return buildingService
            .getBuilding()
            .then((building) => {
                const buildingCity = _.find(cities, { id: _.get(building, 'address.cityId') });
                const cityName = _.get(buildingCity, 'name');
                _.set(building, 'address.cityName', cityName);
                dispatch(setCurrentBuildingAction(building));
            })
            .finally(() => {
                dispatch(setIsLoadingAction(false));
            });
    };
}

export function getAllBuildingLocations() {
    return (dispatch) => {
        dispatch(setIsLoadingAction(true));
        return buildingService
            .getAllBuildings()
            .then((buildings) => {
                const buildingLocations = _.map(buildings, (building) => ({
                    id: _.get(building, 'id'),
                    name: _.get(building, 'name'),
                    addressLine1: _.get(building, 'address.addressLine1'),
                    addressLine2: _.get(building, 'address.addressLine2'),
                    latitude: _.get(building, 'address.latitude'),
                    longitude: _.get(building, 'address.longitude'),
                    cityId: _.get(building, 'address.cityId'),
                }));
                dispatch(setBuildingLocationsAction(buildingLocations));
            })
            .finally(() => {
                dispatch(setIsLoadingAction(false));
            });
    };
}

export function getAllBuildings() {
    return (dispatch) => {
        dispatch(setIsLoadingAction(true));
        return buildingService
            .getAllBuildings()
            .then((buildings) => {
                const updatedBuildingObjects = {};
                _.forEach(buildings, (building) => {
                    updatedBuildingObjects[building.id] = building;
                });
                dispatch(setBuildingsAction(updatedBuildingObjects));
            })
            .finally(() => {
                dispatch(setIsLoadingAction(false));
            });
    };
}

export function getAllBuildingsWithImages() {
    return async (dispatch) => {
        dispatch(setIsLoadingAction(true));
        return buildingService
            .getAllBuildings()
            .then(async (buildings) => {
                const updatedBuildingObjects = {};
                const buildingsWithImages = await mediaService.generateBuildingImageUrls(buildings);
                _.forEach(buildingsWithImages, (building) => {
                    updatedBuildingObjects[building.id] = building;
                });
                dispatch(setBuildingsAction(updatedBuildingObjects));
            })
            .finally(() => {
                dispatch(setIsLoadingAction(false));
            });
    };
}

export function setBuildings(buildings, setIsAvailable) {
    return (dispatch) => {
        if (setIsAvailable) {
            _.forEach(buildings, (building) => {
                _.set(building, 'isAvailable', true);
            });
        }

        dispatch(
            setBuildingsAction(
                _.orderBy(
                    buildings,
                    ['isAvailable', 'acceptingProvisionalBookings'],
                    ['desc', 'desc'],
                ),
            ),
        );
    };
}

export function getBuildingsByCityId(cityId, cityName) {
    return async (dispatch) => {
        dispatch(setIsLoadingAction(true));

        return buildingService
            .getBuildingByCityId(cityId)
            .then(async (buildings) => {
                if (cityName) {
                    _.forEach(buildings, (building) => {
                        _.set(building, 'city', cityName);
                    });
                }

                const buildingsWithImages = await mediaService.generateBuildingImageUrls(buildings);
                dispatch(setBuildingsAction(buildingsWithImages));
            })
            .finally(dispatch(setIsLoadingAction(false)));
    };
}

export function getBuildingById(buildingId) {
    return (dispatch) => {
        dispatch(setIsLoadingAction(true));
        return buildingService
            .getBuildingById(buildingId)
            .then(async (building) => {
                const buildingWithImages = mediaService.generateSingleBuildingImageUrls(building);
                dispatch(setSelectedBuildingAction(buildingWithImages));
            })
            .finally(dispatch(setIsLoadingAction(false)));
    };
}

export function getAllBuildingsByCity() {
    return async (dispatch, getState) => {
        dispatch(setIsLoadingAction(true));
        const cities = _.map(getState().cityReducer.cities, (city) => city);

        const allBuildings = [];
        const updateCities = await Promise.map(cities, async (_city) => {
            const city = _city;
            const fetchedBuildings = await buildingService.getBuildingByCityId(city.id);

            const buildings = mediaService.generateBuildingImageUrls(fetchedBuildings);
            _.map(buildings, (_building) => {
                const building = _building;
                building.city = city.name;
                mergePrivateAndSharedRoomTypes(building);
            });

            allBuildings.push(buildings);
            city.hasNewBuildings =
                _.indexOf(buildings, { acceptingProvisionalBookings: false }) >= 0;
            return city;
        });

        const updatedBuildingObjects = {};
        _.forEach(allBuildings, (building) => {
            _.forEach(building, (buildingItem) => {
                const buildingId = buildingItem.id;
                updatedBuildingObjects[buildingId] = buildingItem;
            });
        });

        dispatch(setBuildingsAction(updatedBuildingObjects));

        const updatedCityObjects = {};
        _.forEach(updateCities, (city) => {
            updatedCityObjects[city.id] = city;
        });

        dispatch(setCitiesAction(updatedCityObjects));
    };
}

function mergePrivateAndSharedRoomTypes(building) {
    const privateRoomTypes = _.get(building, 'privateRoomTypes', []);
    const sharedRoomTypes = _.get(building, 'sharedRoomTypes', []);
    const streetAddress = _.get(building, 'address.addressLine1', '');

    _.unset(building, 'privateRoomTypes');
    _.unset(building, 'sharedRoomTypes');

    _.setProperty(privateRoomTypes, 'arrangementType', 'private');
    _.setProperty(sharedRoomTypes, 'arrangementType', 'shared');
    _.setProperty([...privateRoomTypes, ...sharedRoomTypes], 'streetAddress', streetAddress);

    _.set(building, 'roomTypes', [...privateRoomTypes, ...sharedRoomTypes]);
}
