import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForward } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';

import { theme as themeColors } from '../../../utils/theme';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        borderRadius: 4,
        border: '1px solid #EAEAEA',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    cardContainerHover: {
        borderRadius: 4,
        border: '1px solid #EAEAEA',
        backgroundColor: themeColors.featuredSpacesBackground,
        boxShadow: '8px 11.75px 49.6553px rgba(0, 0, 0, 0.04)',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    iconContainer: {
        display: 'flex',
        minWidth: 80,
        minHeight: 80,
        margin: 20,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        [theme.breakpoints.down('md')]: {
            minWidth: 48,
            minHeight: 48,
        },
    },
    iconAndTextContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    iconImage: {
        width: 28,
        height: 28,
        [theme.breakpoints.down('md')]: {
            width: 20,
            height: 20,
        },
    },
    iconButton: {
        border: '1.5px solid white',
        margin: 20,
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    iconButtonHover: {
        backgroundColor: themeColors.lightButtonBackground,
        margin: 20,
    },
    arrowIcon: {
        color: themeColors.white,
    },
    arrowIconHover: {
        color: themeColors.primaryLight,
    },
}));

const FindASpaceCard = (props) => {
    const { imageSource, imageAlt, title, description, onClick, hideButton } = props;
    const classes = useStyles();

    const [isHovered, setIsHovered] = React.useState(false);

    const onHover = () => {
        setIsHovered(true);
    };

    const onHoverEnd = () => {
        setIsHovered(false);
    };

    return (
        <div
            className={isHovered ? classes.cardContainerHover : classes.cardContainer}
            onMouseOver={onHover}
            onFocus={onHover}
            onMouseLeave={onHoverEnd}
        >
            <div className={classes.iconAndTextContainer}>
                <ImageContainer className={classes.iconContainer} isHovered={isHovered}>
                    <img src={imageSource} alt={imageAlt} className={classes.iconImage} />
                </ImageContainer>
                <div>
                    <h5>{title}</h5>
                    <p>{description}</p>
                </div>
            </div>
            {!hideButton && (
                <IconButton
                    onClick={onClick}
                    color="inherit"
                    className={isHovered ? classes.iconButtonHover : classes.iconButton}
                >
                    <ArrowForward
                        className={isHovered ? classes.arrowIconHover : classes.arrowIcon}
                    />
                </IconButton>
            )}
        </div>
    );
};

FindASpaceCard.propTypes = {
    imageSource: PropTypes.string,
    imageAlt: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    onClick: PropTypes.func,
    hideButton: PropTypes.bool,
};

export default FindASpaceCard;

const ImageContainer = styled.div`
    background-color: ${(props) =>
        props.isHovered ? themeColors.primaryLight : themeColors.subheadingColor};
`;
